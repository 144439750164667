function btnRipple(btn) {

	btn.addEventListener('click', function (event) {
		event.preventDefault();

		var ripple =  document.createElement('div');

		ripple.classList.add('ripple');
		ripple.style.setProperty('--x', event.offsetX+'px');
		ripple.style.setProperty('--y', event.offsetY+'px');
		ripple.addEventListener('animationend', function() {
			btn.removeChild(ripple);

			if(btn.rippleCallback) btn.rippleCallback();

			isLink();
		});

		btn.appendChild(ripple);
	});

	function isLink () {
		if(btn.tagName === 'a' || btn.tagName === 'A') {
			let href = btn.getAttribute('href');

			if(href != '#') {
				let fakeLink = document.createElement('a');

				fakeLink.href = href;

				if(btn.hasAttribute('target')) {
					fakeLink.setAttribute('target', btn.getAttribute('target'));
				}

				document.body.append(fakeLink);

				fakeLink.click();
			}
		}
	}
}

const btnsRiple = document.querySelectorAll('.btn-ripple');

for(var i=0, btn; btn=btnsRiple[i]; i++) {
	btnRipple(btn);
}

(function() {
	var ctrlsNav = $('.nav__planos ol');
	var ctrls  	 = $('.nav__planos li');
	var sizes  	 = [];
	var slider 	 = $('#carouselPlanos');

	if(slider.length > 0) {


		ctrls.each(function () {
			$(this).css('--w', $(this).css('width'))

			sizes.push({
				width : $(this).css('width'),
				target: $(this),
				left  : $(this).position().left+'px',
				scroll: $(this).position().left - 4
			});

		});

		slider.data('ctrls.sizes', sizes);

		ctrlsNav.css({
			'--w' : sizes[0].width,
			'--l' : sizes[0].left,
		});

		slider.on('slide.bs.carousel', function(event) {
			var to = event.to;

			var ctrl = slider.data('ctrls.sizes');

			ctrlsNav.css({
				'--w' : ctrl[to].width,
				'--l' : ctrl[to].left,
			});

			ctrlsNav.scrollLeft(ctrl[to].scroll);
		});

	}
})();

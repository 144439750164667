(function() {
	let calculadora = document.querySelector('.calculadora');

	if(calculadora) {
		let iframe = document.createElement('iframe');
		let body = document.body;
		let html = document.querySelector('html');
		let interval;

		iframe.src = calculadora.dataset.load;

		calculadora.appendChild(iframe);

		iframe.addEventListener('load', function() {
			let ihtml = iframe.contentWindow.document.querySelector('html');
			let ihead = iframe.contentWindow.document.head;
			let ibody = iframe.contentWindow.document.body;

			let style = document.createElement('style');

			style.innerHTML = `
			html{
				font-size: 16px;
			}

			.uppercase{
				font-size: 20px;
			}
			body{
				background: var(--bgBody, #fff);
				color: var(--colorBody, #212529);
				font-size: 1rem;
			}

			.contrast {
			    --bgBody: #212529;
			    --colorBody: #FFF;
			    --azul: #464646;
			    --azulConstrastColor: #fff;
			    --laranja: orange;
			    --faixaTopo: #1f1f1f;
			    --bgMobileControls: #464646;
			    --bgBtnMobileControls: #464646;
			    --colorBtnMobileControls: #d5662f;
			    --bgBtnMobileControlsHover: #393939;
			    --backdrop: rgba(31,31,31,0.5);
			    --bgBtnBoleto: #d5662f;
			    --bgBtnBoletoHover: #c35b27;
			    --colorMenu: #FFF;
			    --shadowDropdown: drop-shadow(0 0 2px rgba(255,255,255,.4));
			    --bgDropdown: #2c3237;
			    --dropdownActiveBg: #d5662f;
			    --dropdownActiveColor: #FFF;
			    --bgDropdowmMobile: #393939;
			    --colorDropdownMobileActive: orange;
			    --bgRodape: #2c2c2c;
			    --colorRodape: #FFF;
			    --bgAngleRodape: #212529;
			    --bgInputNewsletter: #2c2c2c;
			    --bgCreditos: #1f1f1f;
			    --bgBtnTrabalheConoscoRodape: #1f1f1f;
			    --colorBtnTrabalheConoscoRodape: #FFF;
			    --bgBtnTrabalheConoscoRodapeHover: #2f2f2f;
			    --colorBtnTrabalheConoscoRodapeHover: orange;
			    --controlsBanner: var(--primary);
			    --sobreH1: #FFF;
			    --developementMSG: #d5662f;
			    --fontSizeDevelopementMSG: calc(20px + 60 * ((100vw - 320px) / 680));
			    --nomeServico: orange;
			    --servicoDescription: #FFF;
			    --paginacaoDefaultBg: var(--primary);
			    --shadowPlano: none;
			    --shadowPlanoHover: 0 0 10px var(--primary);
			    --h2FAQ: #d5662f;
			    --bgBtnFAQ: #d5662f;
			    --colorBtnFAQ: #FFF;
			    --bgBtnHoverFAQ: #ae5123;
			    --colorBtnHoverFAQ: #FFF;
			    --colorCategoriaFAQ: #FFF;
			    --colorActiveCategoriaFAQ: #d5662f;
			    --bgPerguntaAtiva: var(--primary);
			    --colorTHumbDesconto: #FFF;
			    --bgCupomDesconto: #444;
			    --colorCupomDesconto: #FFF;
			    --colorTitleCupomDesconto: #d5662f;
			}
			`;

			ihead.appendChild(style);

			calculadora.removeAttribute('data-load');


			spyHeight();

			function spyHeight() {
				let height = ibody.scrollHeight;

				calculadora.style.setProperty('height', height+'px');
				ibody.className = body.className;
				ihtml.className = html.className;

				interval = requestAnimationFrame(spyHeight);
			}
		});
	}
})();

$.fn.mdInput = function() {
	return this.each(function() {
		var input = $(this);
		var group = $(this).parents('.md__form__group');

		input.focus(function() {
			group.addClass('focus');
		});

		input.blur(function() {
			if(input.val() == '') {
				group.removeClass('focus');
			}
		});
	});
};

$.fn.mdInputFile = function() {
	return this.each(function() {
		var input  = $(this);
		var group  = $(this).parents('.md__form__group');
		var label  = input.siblings('label.md__form__label');

		group.append('<label for="'+this.id+'" class="form-group md__form__input md__form__fileOutput"><span></span></label>');

		var output = group.find('.md__form__fileOutput span');

		input.addClass('md__form__inputFile--ready');
		input.change(function() {
			output.text(this.files[0].name || 'anexar currículo');
			group.addClass('focus');
		});
	});
}

$.fn.mdSelect = function() {
	var observables = [];

	check();

	function check() {
		if(observables.length > 0) {
			observables.forEach($component => {
				if($component.select.val() != '') {
					$component.parent.addClass('focus');
				}
			});
		}

		requestAnimationFrame(check);
	}

	return this.each(function() {
		var $select = $(this);
		var $parent = $(this).parents('.md__form__group');

		$select.focus(function() {
			$parent.addClass('focus');
		});

		$select.blur(function() {
			if($select.val() === '') {
				$parent.removeClass('focus')
			}
		})

		observables.push({
			select: $select,
			parent: $parent
		})
	});
}

$('.md__form__fileInput').mdInputFile();
$('.md__form__input').mdInput();
$('.md__form__select').mdSelect();

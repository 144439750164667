(function() {
	let Eventos = {};

	/**
	 * @param {String} event Nome do evento
	 * @param {Function} callback Função de callback
	 */
	function $on(event, callback) {
		if(!Eventos[event]) {
			Eventos[event] = [];
		}

		Eventos[event].push(callback);
	}


	/**
	 *
	 * @param {String} event Nome do evento a ser disparado
	 * @param {Object} data Dados que serão populados no evento
	 */
	function $emit(event, data) {
		if(Eventos[event]) {
			Eventos[event].forEach(Callback => Callback(data));
		}
	}

	// Registrando Globalmente
	window.$on 		= $on;
	window.$emit 	= $emit;

})();

(function() {
	var banner = document.querySelector('#banner');

	if(banner) {
		check();

		function check() {
			banner.style.setProperty('--w', banner.offsetWidth);
			banner.style.setProperty('--h', parseInt((1280 / 1920) * banner.offsetWidth) + 'px') ;
		}
	}
})();

(function() {
	var topo    = document.querySelector('.topo');
	var menu    = document.querySelector('.topo__main');
	var body    = document.body;

	// MENU======================================================
	var btnMenu = document.querySelector('[data-toggle="menu"]');
	btnMenu.rippleCallback = function() {
		var active = 'topo__main--shown';

		menu.classList.add(active);

		backdrop(menu, active);

		btnMenu.setAttribute('aria-expanded', true);
	}
	// SEARCH====================================================
	var btnSearch = document.querySelectorAll('[data-toggle="search"]');
	var search   = document.querySelector('.search');

	for(var i=0, btn; btn=btnSearch[i]; i++) {

		btn.rippleCallback = searchCallback.bind(btn)


	}

	function searchCallback() {
		var active = 'search--shown';

		search.classList.add(active);

		backdrop(search, active);

		this.setAttribute('aria-expanded', true);
	}

	//***********************************************************
	function backdrop (target, classActive) {
		var backdrop = document.createElement('div');

		backdrop.classList.add('backdrop');
		body.classList.add(classActive);
		topo.appendChild(backdrop);

		backdrop.addEventListener('click', function() {
			backdrop.classList.add('hide');
			target.classList.remove(classActive);

			backdrop.addEventListener('animationend', function() {
				topo.removeChild(backdrop);
				body.classList.remove(classActive);
				btnMenu.setAttribute('aria-expanded', false);
			});
		});
	}
})();

(function() {
	var links 		= $('.vantagens__link');
	var container 	= $('#vantagensContainer');
	var active      = 'vantagens__link--active'

	links.each(function (index, el) {
		var link = $(this);
		var url  = link.attr('href');

		if(index === 0) {
			link.trigger('click');
		}

		link.click(function(e) {
			e.preventDefault();

			if(link.hasClass(active)) return false;

			var startTime = new Date().getTime();

			links.removeClass(active);
			link.addClass(active);

			if(link.data('vantagens.cache')) {
				container.html(loader());

				setTimeout(function() {
					render(link.data('vantagens.cache'));
				}, 600)

				return false;
			}

			$.ajax({
				url: url,
				beforeSend: function() {
					container.html(loader())
				}
			})
			.done(function(data) {
				var content = '';

				$(data).each(function() {
					content += `<article class="vantagem">
						${this.img ? `<div class="vantagem__img">\n<lazy-image src="${this.img}" alt="${this.title}"><lazy-image>\n</div>` : ''}
						<div class="vantagem__content">
							<h1 class="vantagem__title">${this.title}</h1>
							<div class="vantagem__textarea">${this.content}</div>
						</div>
					</article>`;
				});

				var currentTime = new Date().getTime();
				var timeToLoad  = currentTime - startTime;

				link.data('vantagens.cache', content);

				if(timeToLoad >= 600) {

					render(content);

				}else {
					setTimeout(function() {

						render(content);

					}, (600 - timeToLoad));
				}

			});
		});
	});

	function render(content) {
		container.html(content);

		container.find('[data-animate]').each(function() {
			AnimatedViewport.observe(this);
		});
	}

	function loader() {
		var dots = '';

		for(let i=0;i<5; i++) {
			dots += `<div class="dot" style="--i: ${i}"></div>`;
		}
		return `<div class="loader" style="--n: 5;">${dots}</div>`;
	}
})();


(function() {
	cms();

	function cms () {
		document.querySelectorAll('[style]').forEach(element => {
			let style = element.getAttribute('style');
			var hasFontSize = style.search('font-size');

			if(hasFontSize) {
				var fontSize = element.style.getPropertyValue('font-size');

				if(fontSize.indexOf('px')) {
					fontSize = fontSize.replace('px','');
					fontSize = parseFloat(fontSize);

					fontSize = (fontSize / 16);

					element.style.setProperty('font-size', fontSize+'rem');
				}

			}
		});

		$('table').each(function() {
			if($(this).parents('.table-responsive').length == 0) {
				$(this).wrapAll('<div class="table-responsive"></div>');
			}
		});
	}
})();

(function () {
	const $get = seletor => [].slice.call(document.querySelectorAll(seletor));

	// DOM ELEMENTS
	const BTNS 		= $get('[data-toggle="addDependente"]');
	const TEMPLATE 	= document.querySelector('#dependente');

	// Registrando o evento de click no BTN
	BTNS.map(BTN => {
		const CONTAINER = document.querySelector(BTN.dataset.target);
		let N = 0;

		BTN.addEventListener('click', event => {
			event.preventDefault();
			N++;

			if(TEMPLATE) {
				// Clonando o conteudo de dentro de <template>
				let template 	= TEMPLATE.content.querySelector('.form-group').cloneNode(true);

				// Pegando os elementos de dentro de template
				let input 		= template.querySelector('.form-control');
				let label 		= template.querySelector('label');
				let button 		= template.querySelector('.btn');

				// Gerando um UUID com a hash = Date.now()
				const UUID  		= `dependente-${Date.now()}`;

				// Setando placeholde e counter(s)
				let dependentes = CONTAINER.querySelectorAll('.form-group');
				let placeholder = label.innerHTML;
				let n 			= dependentes.length + 1;

				input.setAttribute('placeholder', placeholder);
				label.innerHTML = placeholder.replace(/{{n}}/g, n);

				input.setAttribute('id', UUID);
				label.setAttribute('for', UUID);

				button.addEventListener('click', function(event) {
					event.preventDefault();

					CONTAINER.removeChild(template);

					labelText();

					window.$emit('dependente.removido', {
						id: UUID
					})
				})

				CONTAINER.appendChild(template);

				labelText();

				window.$emit('dependente.adicionado', {
					id: UUID
				});

				function labelText() {
					let itens = CONTAINER.querySelectorAll('.form-group');
					// let i = itens.length + 1;

					itens.forEach((el,i) => {
						let label = el.querySelector('label');

						label.innerHTML = placeholder.replace(/{{n}}/g, (i+1))
					});
				}
			}
		})
	})
})();

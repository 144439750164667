(function() {
	var topo = document.querySelector('.topo');
	var faixaTopo = document.querySelector('.topo__faixa');

	_check();

	function _check() {
		var h = faixaTopo.offsetHeight;

		topo.style.setProperty('--offset', -h+'px');

		if(window.scrollY >= h) {
			topo.classList.add('fx');
		}else {
			topo.classList.remove('fx');
		}

		requestAnimationFrame(_check);
	}
})();

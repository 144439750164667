(function() {
	var observer = new IntersectionObserver(entries=> {
		entries.forEach(entry => {
			if(entry.isIntersecting) {
				entry.target.run();
				observer.unobserve(entry.target);
			}
		})
	}, {
		treshold: 0.25
	});

	var elements = document.querySelectorAll('.typing-animation');

	elements.forEach(el => {
		var text = el.innerText;

		el.innerHTML = '';

		el.run = function () {
			Typing(el, text);
		};

		observer.observe(el);
	});

	function Typing(el, text) {
		var next     = el.dataset.next;
		var i        = 0;
		var interval = setInterval(write, 50);

		next = document.querySelectorAll(next);

		el.innerHTML = '';

		el.classList.add('typing');

		function write() {
			if(i < text.length) {
				el.innerHTML += text.charAt(i);
				// el.innerHTML += el.innerHTML.replace('\n', '<br>')

			}else {

				next.forEach(target=> {
					target.classList.remove('typing-hide');
					target.classList.add('show')
				});
				el.classList.remove('typing');
				el.classList.remove('show');

				clearInterval(interval);
			}

			i++
		}
	}
})();
